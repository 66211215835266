import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'KandidateString';
  beforeEdit: string;
  afterEdit: string;


  convert() {
    this.afterEdit = this.beforeEdit.replace(/(\r\n|\n|\r)/gm, '" OR "');
    this.afterEdit = '"' + this.afterEdit + '"';
  }

}
